<template lang='pug'>
  loadingOverlay(v-if='loading || !checkOpt' message='Loading . . .' :zIndex='999' attach)
  #GPT.main-page.page-noScroll.pb-0.position-relative(v-else style='background-color: #FFF;height:100vh;')
    v-row#page-head.d-flex.align-center.justify-md-space-between.upmeeting_select
      v-col.d-flex.align-center.pl-3.pt-5(cols='12')
        img(src='../../static/loginPage/images/logoface_1.svg', alt='' width='30px')
        h4.pl-3.pt-2.f-text-highlight-word.my-0 {{$t('UPMEETING.ANN')}}
      v-col(cols="12").pt-0.pb-0
        p.d-flex.align-center.position-relative.input-has-label.input-has-label-no-border.ai_notebook_select
          label.pt-3
            h6.t-black.f-text-highlight-word.fontTeams {{$t('GPT_MEETINGRECAP.AN')}} :
          v-autocomplete.mt-1(
              v-model='ai_notebook'
              :items="ai_notebook_list",
              item-text="name",
              item-value="id", solo='solo'
              :menu-props='{closeOnClick:true}'
              dense='dense'
              @change='change_ai_notebook'
              return-object=true
            )
            template(v-slot:selection="data")
                span.small_word.pl-1(class='ellipsis' :title='data.item.name') {{data.item.name}}
        v-row(v-if='change_dialog').mt-2.warn-border.mb-1.fontTeams
          v-col(cols='2').pt-2
            div(style='height:30px;width:30px;background-color:#d6d5d5;border-radius: 50px;').d-flex.justify-center.align-cneter
              v-icon(color='#59abb6' size='20') mdi-alert
          v-col(cols='10').pt-2.pr-6
            span {{ $t("UPMEETING.CHANGE_AN") }}
            v-btn(style='margin-top:-10px;margin-right:-10px;' absolute top right icon :ripple='false' @click='onCancel("change_dialog")' width='18px' height='18px' color='cancel' depressed dark)
              v-icon(size='18' color='#d6d5d5') mdi-close
            v-row.d-flex.justify-space-between.pt-3
                v-col(cols='6').pl-0
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='25' color='#59abb6' depressed )
                        .t-white().fontTeams {{$t('GENERAL.CONFIRM')}}
                v-col(cols='6').pl-0
                    v-btn(:ripple='false' @click='onCancel("change_dialog")' width='100%' height='25' color='#d6d5d5' depressed dark) 
                      span(style='color:#b5b1b1;').fontTeams {{$t('GENERAL.CANCEL')}}
    #page-inner.scroll-x.pt-md-6.pb-0.height_100vh.fontTeams(style='padding-top:10px;')
      .data-area.upmeeting_tab.pl-4
          v-tabs(v-model='tab' background-color='transparent'  id='gptupload')
                v-tab(v-for='item in tabs' :key='item.id' :href='`#tab-${item.id}`'  style='display:flex; justify-content:center !important;')
                    span.span {{ item.name }}
                v-tab-item.scrollbar.pr-2(key='ai_note' value='tab-1' :transition='false' :reverse-transition='false' :class='change_dialog? "upmeeting-low-height":"upmeeting-height"')
                  
                  v-row(v-if='keys_dialog').warn-border.mt-3.pb-0
                    v-col(cols='2').pt-2.pb-0
                      div(style='height:30px;width:30px;background-color:#d6d5d5;border-radius: 50px;').d-flex.justify-center.align-cneter
                        v-icon(color='#59abb6' size='20') mdi-lightbulb-outline
                    v-col(cols="10" :class='isOver? "pb-2":"pb-0"')
                      p {{complete_keys+"/"+fields.length}}{{$t('UPMEETING.NOTE_C')}}
                      p.pb-0.mb-0(v-if='isOver') {{$t('UPMEETING.MEETING_OVER')}}
                      a(v-if='isOver' @click='goToRecord' style='color:#518bf2;') {{meeting_data.name}}
                      v-btn(style='margin-top:-10px;margin-right:-10px;' absolute top right icon :ripple='false' @click='onCancel("keys_dialog")' width='18px' height='18px' color='cancel' depressed dark)
                        v-icon(size='18' color='#d6d5d5') mdi-close
                  v-row(v-for='item,index in fields' :key='item.column' style='width:263px;max-width:263px;' :class='index==0? "pt-6":"pt-3"')
                    p.d-flex.align-center.input-has-label.input-has-label-no-border-min-width.position-relative
                      label.ellipsis(style='width:70px;max-width:70px;' :title='item.name')
                        //- h6.t-black {{$t('ADD_AIBOTS.NAME')}} :
                        h6.f-text-highlight-word.t-black.ellipsis.fontTeams {{item.name}}
                      //- v-textarea.v-text-field_low_z-index.span.textarea-style(solo flat dense v-model='item.value' readonly style='padding-top:0.1rem;' no-resize rows='5') 
                      div.scrollbar(style='max-width:165px;min-width:165px;max-height:86px;') 
                        v-icon(v-if='item.type == "bol" && (item.value == true || item.value == "true")' size='20') mdi-checkbox-marked
                        v-icon(v-else-if='item.type == "bol" && (item.value != false || item.value != "false")' size='20') mdi-checkbox-blank-outline
                        span(v-else-if='item.type == "opt"') {{getOptStr(item)}}
                        span(v-else) {{item.value != "null"? item.value:""}}

                v-tab-item.scrollbar(key='transcript' value='tab-2' :transition='false' :reverse-transition='false' :class='change_dialog? "upmeeting-low-height":"upmeeting-height"')
                  v-card.elevation-0
                    v-card-text.pa-0.pt-4
                        v-spacer.mt-10.small_space(v-for='item,index in messageData' :key='"msg-"+item.index' :ref="'msg-'+item.index" id="trackMsg" )
                            v-row.chat_icon_div.d-flex.justify-start(color="rgba(0,0,0,0)" flat class="mb-0")
                                //- div.chat_icon_div(v-if='item.question')
                                //-   v-btn.chat_icon_bottom(width='0' height='32' color='green' plain border-0 @click='onCopyMsg(item.question)') {{$t('GENERAL.COPY')}}
                                v-row
                                    v-col(style='max-width:40px' justify='center' align='center')
                                        v-avatar(class="" size="28" elevation="10")
                                            v-icon.chat_icon_backgroung_color(:title=`item.name`) {{ item.name.slice(0,1) }}
                                        //- span(style='white-space:nowrap;font-size:0.75rem;') {{item.time}}
                                        //- span(style='white-space:nowrap;') {{getLittleFormatTime(item.time).slice(-5)}}
                                    v-col.pa-0.pl-4(style='max-width:210px;width:210px;margin-top:8px;')
                                        v-card.rounded-card(class="" color=""  flat)
                                            div.px-0.pb-3()
                                                div.d-flex.justify-start.mb-0
                                                    span.fontTeams(style='font-weight:600;') {{item.name}} 
                                                    span.pl-3.fontTeams(style='white-space:nowrap;font-size:0.75rem;padding-top:1px;color:gray;') {{item.time}}
                                                div.upchat-message-div.d-flex.justify-start.mb-4
                                                    span.can-select-text.fontTeams(v-html='highlight(item.text)' style='line-height:1.7')
                  //- v-divider.divider.ma-0
    v-dialog(v-model='lastmeetDialog' width='360' content-class='statusDialog' persistent)
      lastmeet-dialog(@emitLastmeetDialog='onEmitLastmeetDialog' :isGoogle='isGoogle')
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import lastmeetDialog from "@/components/GPT_Dialog/UpMeet/lastmeetDialog";
import i18n from '/common/plugins/vue-i18n.js';
import UpMeetingService from "@/services/UpMeetingService";
import PreferenceService from "@/services/PreferenceService";
import CustomFieldDataService from "@/services/CustomFieldDataService";

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    lastmeetDialog
  },
  data() {
    return {
      // Dialog
      loading:false,
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      lastmeetDialog: false,
      // Data
      change_dialog:false,
      keys_dialog:true,
      fields:[],
      tab:1,
      tabs:[
        {
            id: 1,
            key: 'ai_note',
            name: i18n.t('GPT_MEETINGRECAP.ANs')
        },
        {
            id: 2,
            key: 'transcript',
            name: i18n.t('GPT_MEETINGRECAP.TS')
        },
      ],
      complete_keys:0,
      isOver:false,
      current_ai_notebook:null,
      ai_notebook:null,
      ai_notebook_list:[],
      meeting_data:null,
      messageData:[],
      searchMsgList: [],
      jot_field_definition:'',
      meeting_summary:null,
      optList:{},
      checkOpt:false,
      isGoogle:false,
    };
  },
  async created() {
    this.loading = true;
    let jot_time = 40000;
    await PreferenceService.getUPGPT_COPILOT().then((response)=>{
        jot_time = response.data.jot_time ? response.data.jot_time:jot_time;
    }).then(()=>{
        // 傳值給CE
        parent.postMessage({
            isUpday:true,
            form: "UpMeeting_Login",
            value: ["has_login",jot_time]
        }, "*")
    })
    await this.getAiNoteBookList();
    // this.fields = this.getFields();
    // this.ai_notebook = this.ai_notebook_list[0];
    // this.current_ai_notebook = this.ai_notebook;
    // await UpMeetingService.getMeetingRecapDetail('b88bff3b-c3d3-45bc-af15-298151955952').then((response)=>{
    //     console.log("取得資料")
    //     this.meeting_data = response.data;
    //     this.ai_notebook = this.meeting_data.ai_notebook_id;
    //     this.current_ai_notebook = this.meeting_data.ai_notebook_id;
    //     this.fields = this.getFields();
    //     this.messageData = this.meeting_data.transcript;
    //     this.meeting_summary = this.meeting_data.meeting_summary;
    //     console.log(this.meeting_data.ai_notes)
    //     this.updateJotData(this.meeting_data.ai_notes);
    //     this.process_msg();
    //     this.get_jot_field_definition();
    //     // setTimeout(()=>{
    //     //   this.sendToJot(this.messageData);
    //     // },5000)
    // }).finally(()=>{
    //   this.loading = false;
    // })
    window.addEventListener('message', this.receiveMessage, false);
    this.updateJotData();
    this.process_msg();
  },
  destroyed() {
  },
  methods: {
    getOptStr(item){
      return item.value && this.optList[item.column][item.value] ? this.optList[item.column][item.value] : "";
    },
    async receiveMessage(e){
      if(e.data.from=="UpMeeting"){
        // if(e.data.type=="Language"){
        //   i18n.locale = e.data.lang
        // }
        if(e.data.type=="Create New"){
          // console.log("開始建立新Meeting Recpa")
          await UpMeetingService.createMeetingRecap().then((response)=>{
            this.meeting_data = response.data;
            this.ai_notebook = this.meeting_data.ai_notebook_id;
            this.current_ai_notebook = this.meeting_data.ai_notebook_id;
            this.fields = this.getFields();
          }).then(()=>{
            this.get_jot_field_definition();
            this.updateJotData();
            this.updateFieldComplete();
          }).finally(()=>{
            this.loading = false;
            if(e.data.title){
              let editData = {
                "id":this.meeting_data.id,
                "name":e.data.title
              }
              UpMeetingService.editMeetingRecap(editData).then((response)=>{
                this.meeting_data.name = response.data.name;
              })
            }
            parent.postMessage({
                isUpday:true,
                form: "UpMeeting_Create",
                value: this.meeting_data.id
            }, "*")
          })
        }else if(e.data.type=="Google Last Meet"){
          if(this.meeting_data == null){
            await UpMeetingService.getMeetingRecapDetail(e.data.id).then((response)=>{
                this.meeting_data = response.data;
                this.ai_notebook = this.meeting_data.ai_notebook_id;
                this.current_ai_notebook = this.meeting_data.ai_notebook_id;
                this.fields = this.getFields();
                this.messageData = this.meeting_data.transcript;
                this.meeting_summary = this.meeting_data.meeting_summary;
                this.updateJotData(this.meeting_data.ai_notes);
                this.updateFieldComplete();
                this.process_msg();
            }).then(()=>{
              this.get_jot_field_definition();
            }).finally(()=>{
              this.loading = false;
              parent.postMessage({
                  isUpday:true,
                  form: "UpMeeting_Last_Meet",
                  value: this.meeting_data.id
              }, "*")
            })
          }
        }else if(e.data.type=="Record"){
          // console.log("收到文字記錄",e.data)
          if(this.meeting_data == null){
            await UpMeetingService.getMeetingRecapDetail(e.data.id).then((response)=>{
              // console.log("取得Meeting Recap資料")
                this.meeting_data = response.data;
                this.ai_notebook = this.meeting_data.ai_notebook_id;
                this.current_ai_notebook = this.meeting_data.ai_notebook_id;
                this.fields = this.getFields();
                this.messageData = this.meeting_data.transcript;
                this.meeting_summary = this.meeting_data.meeting_summary;
                this.updateJotData(this.meeting_data.ai_notes);
                this.updateFieldComplete();
                this.process_msg();
            }).then(()=>{
              this.get_jot_field_definition();
            }).finally(()=>{
              this.loading = false;
              // console.log("取得meeting recap後分析文字記錄")
              this.sendToJot(e.data.record_data,e.data.use_last_record);
            })
          }else{
            // console.log("分析文字記錄")
            this.sendToJot(e.data.record_data,e.data.use_last_record);
          }
        }else if(e.data.type=="Meeting Over"){
          if(e.data.id == "Google"){
            this.isGoogle = true;
          }
          this.isOver = true;
          this.keys_dialog = true;
          this.lastmeetDialog = true;
        }
      }
    },
    async get_jot_field_definition(){
      // let df = JSON.parse(JSON.stringify(this.current_ai_notebook.field_definition));
      // if(this.current_ai_notebook.record_type == "ai_notes"){
      //   for(let i of df){
      //     i.format_type = i.format_type +" , "+ i.description;
      //   }
      // }
      switch(this.current_ai_notebook.record_type){
        case "ai_notes":
          this.jot_field_definition = this.current_ai_notebook.field_definition.ai_notes;
          break;
        case "opportunity":
          this.jot_field_definition = this.current_ai_notebook.field_definition.opportunity;
          break;
        default:
          this.jot_field_definition = [];
          break;
      }
      if(this.current_ai_notebook.record_type == "ai_notes"){
        for(let i of this.jot_field_definition){
          if(i.column_id.slice(0,3) == "opt"){
            this.optList[i.column_id] = i.option_set;
          }
        }
        this.checkOpt = true;
      }else if(this.current_ai_notebook.record_type == "opportunity"){
          await CustomFieldDataService.getCustomFieldDetail("Opportunity").then((response)=>{
            let cf_data = response.data[0].definition;
            let cf_data_list = cf_data.filter(el=>el.stage_type_id==this.current_ai_notebook.business_process_id || el.stage_type_id == "all");
            cf_data_list.forEach((el)=>{
                for (const [key, value] of Object.entries(el.data)){
                    if(key.slice(0,3)=="opt"){
                      this.optList[key] = value.option_set;
                    }
                }
            })
            for(let i of this.jot_field_definition){
              if(i.column_id && i.column_id.slice(0,3) == "opt"){
                i.option_set = this.optList[i.column_id];
              }
            }
          })
          this.checkOpt = true;
      }
    },
    async sendToJot(record,use_last_record){
      let ori_ai_notes = this.meeting_data.ai_notes;
      let field_definition = [];
      if(ori_ai_notes == null){
          ori_ai_notes = {};
          switch(this.current_ai_notebook.record_type){
              case "ai_notes":
                  field_definition = this.current_ai_notebook.field_definition['ai_notes'];
                  field_definition.forEach((el)=>{
                      ori_ai_notes[el.column_name] = null;
                  })
                  break;
              case "opportunity":
                  field_definition = this.current_ai_notebook.field_definition['opportunity'];
                  field_definition.forEach((el)=>{
                      ori_ai_notes[el.column_name] = null;
                  })
                  break;
              default:
                break;
          }
      }else{
          let column_name_for_key = {};
          switch(this.current_ai_notebook.record_type){
              case "opportunity":
                  field_definition = this.current_ai_notebook.field_definition['opportunity'];
                  field_definition.forEach((el)=>{
                      if(el.source_type == "custom_field"){
                          column_name_for_key[el.column_name] = ori_ai_notes[el.column_id];
                      }else if(el.source_type == "original"){
                          column_name_for_key[el.column_name] = ori_ai_notes[el.column_name];
                      }
                  })
                  break;
              case "ai_notes":
                  field_definition = this.current_ai_notebook.field_definition['ai_notes'];
                  field_definition.forEach((el)=>{
                      column_name_for_key[el.column_name] = ori_ai_notes[el.column_id];
                  })
                  break;
              default:
                break;
          }
          ori_ai_notes = column_name_for_key;
      }
      let data = {
        "meeting_recap_id":this.meeting_data.id,
        "ai_notebook_id":this.current_ai_notebook.id,
        "field_definition":this.jot_field_definition,
        "transcript":record? record:[],
        "language":"Tradionnal Chinese",
        "ori_ai_notes":ori_ai_notes,
        "record_type":this.current_ai_notebook.record_type,
        "meeting_summary":this.meeting_summary,
        "use_last_record":use_last_record == undefined? false : use_last_record
      }
      let key = "";
      await PreferenceService.getApiKey().then((response)=>{
        if(response.data == ""){
          return;
        }
        key = response.data;
      })
      await UpMeetingService.JotAINoteBook(data,key).then((response)=>{
        this.messageData = response.data.transcript;
        this.meeting_data.ai_notes = response.data.openai_response_ai_notes;
        this.meeting_summary = response.data.meeting_summary;
        this.updateJotData(response.data.openai_response_ai_notes);
        this.updateFieldComplete();
        this.process_msg();
      })
    },
    change_ai_notebook(){
      if(this.current_ai_notebook.id != this.ai_notebook.id){
        this.change_dialog = true;
      }else{
        this.change_dialog = false;
      }
    },
    async getAiNoteBookList(){
      await UpMeetingService.getAiNotebookList().then((response)=>{
        this.ai_notebook_list = response.data;
      })
    },
    async onConfirm(){
      this.current_ai_notebook = this.ai_notebook;
      this.get_jot_field_definition();
      this.isEditSub = false;
      this.change_dialog = false;
      this.fields = this.getFields();
      let edit_data = {
        "id" : this.meeting_data.id,
        "name": this.meeting_data.name,
        "ai_notebook_id_id": this.current_ai_notebook.id,
        "ai_notes":null,
        "record_type":this.current_ai_notebook.record_type,
      }
      await UpMeetingService.editMeetingRecap(edit_data).then((response)=>{
        this.meeting_data = response.data;
      })
      this.updateFieldComplete();
    },
    onCancel(val){
      if(val=="change_dialog"){
        this.ai_notebook = this.current_ai_notebook;
        this.change_dialog = false;
      }else if(val=="keys_dialog"){
        this.keys_dialog = false;
      }
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    updateFieldComplete(){
      this.complete_keys = this.fields.reduce((a,b)=>{
        if(b.value!=null){
          a=a+1;
        }
        return a},0)
    },
    updateJotData(data){
      if(data){
        for(let i of this.fields){
          i.value = data[i.column];
        }
      }
    },
    getFields(){
      let opptyField = [
        {
          name:i18n.t('DEAL_DETAIL.EXPECTED_CLOSE_DATE'),
          column:"expected_close_date",
          value:null,
          type:"dat",
        },
        {
          name:i18n.t('DEAL_DETAIL.CLIENT_BUDGET'),
          column:"customer_budget",
          value:null,
          type:"str",
        },
        {
          name:i18n.t('TO_DEAL.BUDGET'),
          column:"budget_confirmed",
          value:null,
          type:"bol",
        },
        {
          name:i18n.t('DEAL_DETAIL.CUSTOMER_NEEDS'),
          column:"customer_needs",
          value:null,
          type:"str",
        },
      ]
      let data = [];
      let field_definition = [];
      switch(this.current_ai_notebook.record_type){
        case "ai_notes":
            field_definition = this.current_ai_notebook.field_definition['ai_notes'];
            field_definition.forEach((el)=>{
              data.push(
                      {
                        name:el.column_name,
                        column:el.column_id,
                        value:null,
                        type:el.column_id.slice(0,3)
                      }
                    );
            })
            break;
        case "opportunity":
            field_definition = this.current_ai_notebook.field_definition['opportunity'];
            field_definition.forEach((el)=>{
                if(el.source_type == "custom_field"){
                    data.push(
                      {
                        name:el.column_name,
                        column:el.column_id,
                        value:null,
                        type:el.column_id.slice(0,3)
                      }
                    );
                }else if(el.source_type == "original"){
                    let oppty_f = opptyField.filter(o=>el.column_name==o.column);
                    if(oppty_f.length>0){
                      data.push(oppty_f[0])
                    }
                }
            })
            break;
        default:
          break;
      }
      return data;
    },
    goToRecord(){
      window.open("/upgpt/MeetingRecap/"+this.meeting_data.id, '_blank').focus();
    },
    highlight(text) {
        if(this.searchMsgList.length == 0) {
            return text;
        }
        return text.replace(new RegExp(this.lastSearchMsg, "gi"), match => {
            return '<span class="highlight">' + match + '</span>';
        });
    },
    process_msg(){
        if(this.messageData){
            try{
                this.messageData = JSON.parse(this.messageData);
            }catch{
                // 
            }
        }else{
          this.messageData = []
        }
        let index = -1;
        this.messageData.forEach(el=>{
            el.index = index++;
            el.text = el.content.map(el=>{return el.text}).join("<br>");
        })
    },
    // Dialog Area ----------------------------------------- [Start]
    showtLastmeetDialog() {
      this.lastmeetDialog = true;
    },
    onEmitLastmeetDialog(val){
      if(val == "New"){
        this.loading = true;
        this.isOver = false;
        this.tab = 1;
        this.meeting_data = null;
        this.messageData = [];
        this.jot_field_definition = '';
        this.meeting_summary = null;
        parent.postMessage({
            isUpday:true,
            form: "ReMeet",
            type:"New"
        }, "*")
      }else if(val == "Last"){
        this.isOver = false;
        parent.postMessage({
            isUpday:true,
            form: "ReMeet",
            type:"Last",
            value: this.meeting_data.id
        }, "*")
      }else if(!val){
        window.removeEventListener('message', this.receiveMessage);
      }
      this.lastmeetDialog = false;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog(val) {
      if(val){
        // 
      }
      this.messageDialog = false;
    },
  },
  watch:{
  }
};
</script>

<style lang="scss">
  .upmeeting_select{
    .ai_notebook_select{
      .v-select__slot{
        min-width: 160px;
        max-height: 22px;
        height: 22px;
        border: 1px solid gray;
        input{
          padding: 0px 0px 0px 10px;
        }
      }
    }
  }
  .warn-border{
    border:1px solid #d6d5d5;
    border-left:4px solid #59abb6;
    border-radius: 5px
  }
  .fontTeams{
    font-size:0.8rem !important;
  }
</style>